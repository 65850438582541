import { useStaticQuery, graphql } from "gatsby";
import loadable from "@loadable/component";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import decodeHTMLEntities from "../utils/decodeHTMLentities.js";
const Parser = loadable(() => import("../parser.js"));

const Image = (props) => {
  const allImages = useStaticQuery(graphql`
    query AllImages {
      allWpMediaItem {
        nodes {
          altText
          title
          caption
          sourceUrl
          databaseId
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(width: 200)
              small: gatsbyImageData(width: 400)
              medium: gatsbyImageData(width: 800)
              large: gatsbyImageData(width: 1000)
              xl: gatsbyImageData(width: 1280)
              full: gatsbyImageData
            }
          }
        }
      }
    }`);
    
  const targetImage = allImages.allWpMediaItem.nodes.find(e => {
    if (props.attributes.id) {
      return e.databaseId.toString() === props.attributes.id.toString();
    }
    if (props.attributes.url) {
      return e.sourceUrl === props.attributes.url;
    }
  });
  const sizeSlug = props.size || "xl";
  const align = (props.attributes.align && props.attributes.align.length) ? "align"+props.attributes.align:undefined;
  const ImageRender = () => {
    if (!targetImage) {
      return <img
        id={ props.anchor }
        style={ props.style }
        className={ align }
        src={ props.attributes.url }
        alt={ decodeHTMLEntities(props.attributes.altText || props.attributes.caption) }
      />;
    }
    return <GatsbyImage
      id={ props.anchor }
      className={ align }
      style={ props.style }
      { ...props.gatsbyImageOptions }
      image={ targetImage.localFile.childImageSharp[sizeSlug] }
      alt={ decodeHTMLEntities(props.attributes.altText || targetImage.altText || props.attributes.caption) }
    />;
  };
  return (<figure className={ "gatsby-image-wrapper " + (align || "") } onClick={ props.onClick }>
    <ImageRender />
    {
      props.attributes.caption
        ? <figcaption>
            {props.attributes.title 
              ?
                <span className="title-figcaption">
                  { props.attributes.title }
                </span>
              : <></>
            }
            <Parser content={props.attributes.caption}/>
          </figcaption>
        : <></>
    }
  </figure>);
};

export default Image;
