import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Polygon, TileLayer, Tooltip } from 'react-leaflet';
import Image from "./image.js";
import { apparenceMarker } from "../../../marker-map.js";

export default function Map(props) {
  const isBrowser = typeof window !== "undefined"; 

  const [accordeonActif, setAccordeonActif] = useState("");
  const gcoMapBlock = useRef(null);
  const gcoMap = useRef(null);
  
  useEffect(() => {
    if (gcoMapBlock.current && gcoMap.current) gcoMap.current.style.height = gcoMapBlock.current.clientHeight + "px";
  }, []);

  const allMaps = useStaticQuery(graphql`
    query GcoMap {
      allWpGcomap {
        nodes {
          databaseId
          slug
          title
          settingsMap {
            coordonneesDepart {
              latitude
              longitude
            }
            activationZoomScroll
            apparenceMap
            iconSize
            iconOpacity
            iconIsTab
            styleZone
            styleZoneHover
            styleZoneActive
            niveauZoomDefault
            niveauZoomMax
            niveauZoomMin
            infosCarte {
              idItemAccordeon
              adressePostale
              description
              email
              fax
              typeCarte
              telephone
              nom
              logo {
                databaseId
              }
              lien {
                target
                title
                url
              }
              coordonneesDuPoint {
                latitude
                longitude
              }
              coordonneesDeLaZone {
                coordonnees
              }
            }
          }
        }
      }
    }
  `);

  /** Filtrage des données du plugin pour extraire la carte devant être affiché sur la page. */
  const infoCarte = allMaps.allWpGcomap.nodes.find(map => map.databaseId === props.attributes.idMap);

  const { 
    settingsMap, 
    databaseId, 
    slug, 
    title 
  } = infoCarte;

  const {
    niveauZoomDefault,
    niveauZoomMax,
    niveauZoomMin,
    iconSize,
    iconIsTab,
    iconOpacity,
    styleZone,
    styleZoneHover,
    styleZoneActive,
    apparenceMap,
    activationZoomScroll,
    coordonneesDepart,
    infosCarte
  } = settingsMap;

  const MapPlaceholder = () => {
    return (
      <p>
        {title}.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    )
  };

  const Icon = ({id}) => {
    const sizeIcon = JSON.parse(iconSize);
    return new L.divIcon({
    iconSize: sizeIcon,
    iconAnchor: [sizeIcon[0]/2, sizeIcon[1]],
    html: `<span class="${id == accordeonActif ? "item-actif" : ""}">${apparenceMarker}</span>`,
    className: `leaflet-icon`,
  })};

  const NewMarker = ({ lat, lng, filialeName, idItemAccordeon }) => {
    return (
      <Marker
        id={`marker-${idItemAccordeon}`}
        opacity={parseFloat(iconOpacity)}
        keyboard={JSON.parse(iconIsTab)}
        position={[lat, lng]}
        icon={ Icon({ id: idItemAccordeon }) }
        zIndexOffset={idItemAccordeon == accordeonActif ? 1000 : 0}
        eventHandlers={{
          click: (e) => {
            document.querySelector(`#detail-${idItemAccordeon}`).toggleAttribute("open");
            if (document.querySelector(`#detail-${idItemAccordeon}`).getAttribute("open") == null) setAccordeonActif("");
            else setAccordeonActif(idItemAccordeon);
          }
        }}
      >
        { filialeName.length 
          ? <Tooltip
            direction="top" 
            offset={[0, -40]} 
            opacity={1} 
            permanent={accordeonActif === idItemAccordeon} 
            sticky={false}
          >{filialeName}</Tooltip> 
          : null }
      </Marker>
    )
  };

  const NewZone = ({ coordonnees, filialeName, idItemAccordeon }) => {
    const coords = JSON.parse(coordonnees.coordonnees);
    const styleHover = JSON.parse(styleZoneHover);
    const styleClassic = JSON.parse(styleZone);
    return (
        <Polygon 
        pathOptions={JSON.parse(styleZone)} 
        positions={coords}
        className={idItemAccordeon == accordeonActif ? "item-actif" : ""}
        eventHandlers={
          {
            mouseover: (event) => event.target.setStyle(styleHover),
            mouseout: (event) => event.target.setStyle(styleClassic),
            click: (e) => {
              document.querySelector(`#detail-${idItemAccordeon}`).toggleAttribute("open");
              if (document.querySelector(`#detail-${idItemAccordeon}`).getAttribute("open") == null) setAccordeonActif("");
              else setAccordeonActif(idItemAccordeon);
            }
          }
        }
      >
        { filialeName.length 
          ? <Tooltip
            direction="top" 
            offset={[0, -40]} 
            opacity={1} 
            permanent={accordeonActif === idItemAccordeon}
            sticky={false}
          >{filialeName}</Tooltip> 
          : null }
      </Polygon>
    )
  };

  const AddressSummary = data => {
    const info = data.info;
    return (
      <>
        <summary className="accordeon-summary">
          {info.nom?.length ? info.nom : "Voir le details" }
        </summary>

        <address>
          {/* Téléphone */}
          { info.telephone
            ? <a href={`tel:${info.telephone}`} className="accordeon-tel">
                {info.telephone}
              </a>
            : <></>
          }

          {/* Fax */}
          { info.fax
            ? <a href={`fax:${info.fax}`} className="accordeon-fax">
                {info.fax}
              </a>
            : <></>
          }

          {/* Email */}
          { info.email
            ? <a href={`mailto:${info.email}`} className="accordeon-email">
                {info.email}
              </a>
            : <></>
          }

          {/* Adresse Postale */}
          { info.adressePostale
            ? <p className="accordeon-address">{info.adressePostale}</p>
            : <></>
          }

          {/* Description */}
          { info.description
            ? <p className="accordeon-description">{info.description}</p>
            : <></>
          }

          {/* Logo */}
          { info.logo?.databaseId
            ? <Image attributes={{id: info.logo.databaseId}} className="accordeon-image" />
            : <></>
          }

          {/* Nom */}
          {/* { info.nom
            ? <p className="accordeon-nom">{info.nom}</p>
            : <></>
          } */}

          {/* Lien Interne/Externe */}
          { info.lien
            ? <div className="wp-block-button accordeon-link">
                <a href={info.lien.url} className="wp-block-button__link" title={info.lien.title} target={info.lien.target}>{info.lien.title}</a>
              </div>
            : <></>
          }
        </address>
      </>
    );
  };

  return (
    (infosCarte === null)
      ? <span style={{ "color": "red", "textAlign": "center", "display": "block" }} >🆘 Votre carte ne contient aucune information !</span>
      : <section className="gco-map" ref={gcoMapBlock}>
        <aside>
          {/* { title.length 
            ? <h3>{ title }</h3>
            : <h3>Informations</h3>
          } */}

          <ul>
            { infosCarte.map((info, i) => 
              !info.nom &&
              !info.logo &&
              !info.lien &&
              !info.telephone &&
              !info.fax &&
              !info.email &&
              !info.adressePostale &&
              !info.description

              ? <span key={i}></span>
              : <details 
                key={`list-${i}`} 
                id={`detail-${info.idItemAccordeon}`}  
                open={accordeonActif === info.idItemAccordeon}
                onClick={() => {
                  if (accordeonActif === info.idItemAccordeon) setAccordeonActif("");
                  else setAccordeonActif(info.idItemAccordeon);
                } }
                >
                  <AddressSummary info={info} />
                </details>
            )}
          </ul>
        </aside>
        { isBrowser 
          ? <div ref={gcoMap}>
              <MapContainer 
                center={[coordonneesDepart[0].latitude, coordonneesDepart[0].longitude]} 
                zoom={niveauZoomDefault} 
                minZoom={niveauZoomMin}
                maxZoom={niveauZoomMax}
                scrollWheelZoom={activationZoomScroll}
                placeholder={<MapPlaceholder />}
              >
                <TileLayer url={apparenceMap} />
                { infosCarte.map((info, i) => {
                  const typeCarte = info.typeCarte;
                  if (typeCarte === "point") 
                    return <NewMarker 
                      key={`point-${i}`} 
                      lat={info.coordonneesDuPoint[0].latitude} 
                      lng={info.coordonneesDuPoint[0].longitude} 
                      apparenceMarker={apparenceMarker} 
                      filialeName={info.nom}
                      idItemAccordeon={info.idItemAccordeon}
                    />
                  else if (typeCarte === "zone") 
                    return <NewZone 
                      key={`zone-${i}`} 
                      coordonnees={info.coordonneesDeLaZone[0]} 
                      filialeName={info.nom}
                      idItemAccordeon={info.idItemAccordeon}
                    />
                })} 
              </MapContainer>
            </div>  
          : <></>
        }
      </section> 
  );
}